div.apuesta {
  // display: flex;
  text-align: center;
  max-height: 50vh;
  padding: auto 2px;
  overflow-y: auto;
  .apuesta_item {
    // margin: 2px;
    border: 1px dotted #888;
    border-radius: 0.25em;
    align-items: center;
    padding: 10px 0px;
    margin: 10px 0px;
    // input {
    // width: 90%;
    // margin: 3px;
    // text-align: center;
    // padding: 2px 0px;
    // }
    select {
      width: 100%;
      margin: 2px;
      overflow: hidden;
      text-align: center;
    }
    .apuesta_item_body {
      justify-content: center;
    }
    div {
      &.inputList {
        min-width: 70px;
        margin: 5px 0px;
        padding: 0px 5px;
        text-align: center;
      }
      &.checkboxList {
        border: 1px solid #888;
        border-radius: 0.8em;
        cursor: pointer;
        margin: 2px;
        min-width: 70px;
        padding: 2px 5px;
        transition: all 0.5s;
        &.active {
          color: #fff;
          background-color: #888;
          border: 1px solid #cf79e0;
        }
      }
    }
  }
}
