img.avatar {
    width: 30px;
    height: 30px;
    border-radius: 5px;
    object-fit: cover;
    cursor: pointer;
    vertical-align: middle;
}
span.avatar {
    vertical-align: middle;
}
