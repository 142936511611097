body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  p,
  label {
    margin: 0;
    word-wrap: break-word;
  }
}
div#root {
  background-color: var(--main-color);
  overflow: hidden;
  height: 100vh;
}

div {
  &.right {
    text-align: end;
  }
  &.center {
    text-align: center;
    span.error {
      color: red;
    }
  }
  &.menu {
    justify-content: center;
    align-content: center;
    overflow-y: auto;
    .menu_item {
      color: #fff;
      cursor: pointer;
      border: 1px solid #fff;
      padding: 5px;
      box-shadow: 0px 0px 5px 0px #ffffff;
      text-align: center;
      border-radius: 0.5em;
      margin: 10px;
      transition: all 0.5s;
      &:hover {
        color: #cf79e0;
        border-color: #cf79e0;
        box-shadow: 0px 0px 10px 0px #cf79e0;
      }
    }
  }
  &.title {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: center;
  }
  &.flex {
    display: flex;
    &.flex_wrap {
      flex-wrap: wrap;
    }
    &.flex_space-around {
      justify-content: space-around;
    }
  }
  &.margin_botom_10 {
    margin-bottom: 10px;
  }
}

input {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type="number"] {
    -moz-appearance: textfield;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

button.button {
  background-color: #cf79e0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  font-size: 16px;
  font-weight: 500;
  padding: 5px 15px;
  margin: 0px 5px;
  &.save {
    background-color: transparent;
    border: 1px solid #cf79e0;
    color: #cf79e0;
    transition: all 0.5s;
    &:hover {
      background-color: #cf79e0;
      // border: none;
      color: white;
    }
  }
}
:root {
  --main-color: #1b2635; //#0b0b0b;
}

.link {
  color: inherit;
  text-decoration: none;
}

@mixin tablet {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: 480px) {
    @content;
  }
}
