.dashboard {
  justify-content: center;
  overflow-x: auto;
  .dashDates {
    text-align: center;
    form {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      > div {
        // border: 1px solid #888;
        padding: 5px;
        label {
          display: block;
        }
      }
    }
  }
  .dashData {
    border: 1px solid #a1539e;
    border-radius: 0.5em;
    text-align: center;
    padding: auto 10px;
    margin: 5px;
  }
  .tableDash {
    padding: 10px;
    overflow: auto;
    max-height: 75vh;
    // background-color: #0d131a;
    // color: white;
    table {
      width: 100%;
      border: 1px solid;
      border-radius: 0.25em;
      text-align: center;

      thead {
        flex: 0 0 auto;

        th {
          &.nowrap {
            white-space: nowrap;
          }
          &.bordeRight_dashed {
            border-right: 1px dashed;
          }
        }
      }
      tbody {
        td.bordeRight_dashed {
          border-right: 1px dashed;
        }
        tr:nth-child(odd) {
          background-color: #a1539e60;
        }
        td {
          &.nowrap {
            white-space: nowrap;
          }
          & > div.nowrap {
            white-space: nowrap;
          }
        }
      }
    }
  }
}
.billing {
  max-height: 50vh;
  table {
    width: 100%;
    border: 1px solid;
    border-radius: 0.25em;
    text-align: center;
    thead {
      flex: 0 0 auto;
      > tr {
        cursor: pointer;
      }
    }
    tbody {
      tr {
        &.billing_row {
          background-color: #7d6b897e;
          cursor: pointer;
        }
        &.billing_row_interno {
          td {
            white-space: nowrap;
          }
          &:nth-child(odd) {
            background-color: #5741574d;
          }
        }
      }
      td.nowrap {
        white-space: nowrap;
      }
    }
  }
}
