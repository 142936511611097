.admin {
    max-width: 1024px;
    margin: auto;
    max-height: 80vh;
    overflow-y: auto;
    > div {
        border: 1px solid #fff;
        border-radius: 1em;
        margin: 10px;
        color: #fff;
        padding: 10px;
    }
}
