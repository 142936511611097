.addUser {
  form {
    > div {
      margin-bottom: 10px;
      &.input_dayWeek {
        padding: 5px;
        border: 1px dashed #6d16a7;
        border-radius: 0.25em;
        width: 95%;
        display: flex;
        align-items: center;
        .input_time {
          display: flex;
          justify-content: space-around;
          text-align: center;
          flex-wrap: wrap;
          input[type="time"] {
            text-align: center;
          }
          & > div {
            max-width: 45%;
            min-width: 130px;
          }
        }
        .input_active {
          margin-top: 5px;
        }
      }
      input {
        width: 95%;
        padding: 5px;
        margin: 0px;
        border-radius: 0.5em;
        border: 1px solid #888;
        &.error {
          border-width: 2px;
          border-color: #6d16a7;
        }
      }
      select {
        width: 100%;
        padding: 5px;
        text-align: center;
      }
      &.input {
        span {
          color: #6d16a7;
        }
        > div.label_password {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}
