.footer {
  color: white;
  position: absolute;
  bottom: 2%;
  left: 0;
  right: 0;
  margin: 0 auto;
  > div {
    text-align: center;
    margin: 5px 0;
    span{
      font-size: 0.8em;
    }
    a {
      cursor: pointer;
      border-radius: 0.25em;
      font-size: 1.5em;
      padding: 3px 5px;
      text-decoration: none;
      transition: all 1s;
      color: #cf79e0;
      &:hover {
        color: #fff;
      }
    }
    &.link {
    }
  }
}
