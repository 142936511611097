.home {
  max-width: 1024px;
  margin: auto;
  .MuiTabs-root {
    margin-top: 30px;
  }
  div.body {
    display: flex;
    height: calc(75vh - 100px);
    overflow-y: auto;
  }
}
